import React from "react";

import { graphql, Link } from "gatsby";
// import { HelmetDatoCms } from "gatsby-source-datocms";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Container from "../components/container";

export default function IndexPage({ data: { posts } }) {
  const allPosts = (posts && posts.nodes) || [];

  return (
    <Layout>
      <Container>
        {/* <HelmetDatoCms seo={blog.seo} favicon={site.favicon} /> */}
        <div className="flex flex-wrap row-auto justify-between gap-6 pt-20">
          {allPosts.map((post) => (
            <Link
              to={`/oferta/${post.slug}`}
              key={post.originalId}
              className="relative xs:basis-1 sm:basis-[calc(50%-12px)] md:basis-[calc(33.3%-16px)] bg-black"
            >
              <small className="absolute z-10 text-white p-2 font-bold text-lg">
                {post.title}
              </small>
              <GatsbyImage
                image={post.image.gatsbyImageData}
                alt={post.image.alt}
                className="hover:opacity-80 transition-opacity"
              />
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <title>Olga Cieślik Profesjonalny opiekun zwierząt</title>
      <meta
        name="description"
        content="Olga Cieślik Profesjonalny opiekun zwierząt"
      />
    </>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }

    posts: allDatoCmsPost {
      nodes {
        slug
        title
        position
        originalId
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
`;
